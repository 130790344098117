<template>
  <div class="finance-account">
    <en-table-layout :tableData="tableData.data" :loading="loading">
      <template slot="toolbar">
       
          <el-form-item label="充值单号" prop="sn">
            <el-input clearable v-model="params.sn" placeholder="请输入充值单号"></el-input>
          </el-form-item>
          <el-form-item label="充值时间" prop="mobile">
            <el-date-picker style="width: 220px" v-model="time_range" type="daterange" align="center" size="medium"
              :editable="false" value-format="timestamp" unlink-panels range-separator="-" start-placeholder="开始日期"
              end-placeholder="结束日期"></el-date-picker>
          </el-form-item>
          <el-form-item label="状态" prop="mobile">
            <el-select v-model="params.audit_status" placeholder="请选择状态" clearable>
              <el-option v-for="(item, index) in statusList" :key="index" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="col-auto">
            <el-button @click="searchEvent" type="primary" size="small">搜索</el-button>
          </el-form-item>
        
      </template>

      <template slot="table-columns">
        <el-table-column label="充值单号" prop="sn" />

        <el-table-column label="充值时间" prop="create_time" width="160">
          <template slot-scope="{row}">{{ row.create_time | unixToDate }}</template>
        </el-table-column>
        <el-table-column label="充值金额" prop="amount" />
        <el-table-column label="充值方式" prop="recharge_way">
          <template slot-scope="{row}">
            <span>{{ row.recharge_way == 0 ? '线下汇款' : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="audit_status">
          <template slot-scope="{row}">
            <span>{{ row.audit_status == 0 ? "待审核" : (row.audit_status == 1 ? "已通过" : "已拒绝") }}</span>
          </template>
        </el-table-column>
        <el-table-column label="提交人" prop="create_by_name" />

        <el-table-column label="操作" width="200">
          <template slot-scope="{row}">
            <el-button size="mini" type="primary" @click="addNotice(row)">查看详情</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :page-size="tableData.page_size" :total="tableData.data_total"></el-pagination>
    </en-table-layout>

    <!--详情-->
    <el-dialog-x-dialog title="充值详情" :visible.sync="editVisible">
      <el-form class="txInfoFrom" :model="txInfo" ref="txInfo" label-width="135px">
        <el-form-item label="站点名称">
          {{ txInfo.shop_name }}
        </el-form-item>
        <el-form-item label="充值单号">
          {{ txInfo.sn }}
        </el-form-item>
        <el-form-item label="充值时间">
          {{ txInfo.create_time | unixToDate }}
        </el-form-item>
        <el-form-item label="状态">
          {{ txInfo.audit_status == 0 ? "待审核" : (txInfo.audit_status == 1 ? "已通过" : "已拒绝") }}
        </el-form-item>
        <el-form-item label="提交人">
          {{ txInfo.create_by_name }}
        </el-form-item>
        <el-form-item label="充值金额">
          {{ txInfo.amount }}
        </el-form-item>
        <el-form-item label="支付方式">
          {{ txInfo.recharge_way == 0 ? '线下汇款' : '' }}
        </el-form-item>
        <el-form-item label="图片凭证">
          <img style="width: 150px;" @click="handlePreview(txInfo.recharge_imgs)" :src="txInfo.recharge_imgs" alt="">
        </el-form-item>
        <el-form-item label="备注" prop="addr">
          {{ txInfo.recharge_remark }}
        </el-form-item>
        <el-form-item label="审核备注">
          {{ txInfo.audit_remark }}
        </el-form-item>
      </el-form>
    </el-dialog-x-dialog>
    <!-- 图片放大 -->
    <el-dialog :visible.sync="dialogImage">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import XDialog from '@/components/x-dialog/x-dialog';
import { getrechargeList, getRechargeDetail } from "@/api/account";
import EnTableLayout from '../../../ui-components/TableLayout/src/main';
export default {
  name: 'rechargeRecords',
  components: { EnTableLayout, XDialog },
  data () {
    return {
      dialogImageUrl: '',
      dialogImage: false,
      statusList: [
        { label: '待审核', value: 0 },
        { label: '已通过', value: 1 },
        { label: '已拒绝', value: 2 },

      ],//审核状态
      provinceList: [],
      time_range: '',//时间
      /** 列表loading状态 */
      loading: false,
      editVisible: false,//审核页
      txInfo: {},
      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20
      },
      /** 列表数据 */
      tableData: {},
      /** 店铺信息 */
      shopInfo: this.$store.getters.shopInfo,
    };
  },
  filters: {
    /**状态 */
    status_filter: value => {
      switch (value) {
        case 0:
          return '待审核';
        case 1:
          return '已通过';
        default:
          return '已拒绝';
      }
    }
  },
  mounted () {
    this.GET_AccountList();
  },
  methods: {
    /** 点击 放大 */
    handlePreview (url) {
      this.dialogImageUrl = url;
      this.dialogImage = true;
    },
    /** 搜索事件触发 */
    searchEvent () {
      this.params.page_no = 1;
      this.GET_AccountList();
    },
    /**关闭审核认证弹窗 */
    cancel () {
      this.editVisible = false//审核页
    },
    /**打开弹窗 */
    addNotice (row = null) {
      getRechargeDetail(row.id).then((response) => {
        this.loadingtitle = "充值详情"
        this.txInfo = {
          ...response
        }
        this.editVisible = true
      })
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_AccountList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_AccountList();
    },
    GET_AccountList () {
      if (this.time_range !== '') {
        this.params.start_create_time = this.time_range[0] / 1000;
        this.params.end_create_time = this.time_range[1] / 1000;
      } else {
        delete this.params.start_create_time
        delete this.params.end_create_time
      }
      this.loading = true;
      let params = {
        ...this.params
      }
      getrechargeList(params).then(response => {
        this.loading = false;
        this.tableData = response;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.finance-account-table-header {
  padding: 16px 16px 0;

  .line {
    font-size: 13px;
    color: #666;
  }
}

/deep/ .el-radio-group {
  .el-radio {
    margin-bottom: 0 !important;
  }
}

.en-table-layout .en-table-layout-toolbar .el-form-item {
  margin-bottom: 8px;
}

.txInfoFrom {
  .el-form-item {
    margin-bottom: 0;
  }
}

/deep/ .el-scrollbar__wrap {
  margin-right: -7px !important;
}
</style>
